import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { MobileLogo } from "./logoMobile"
import {
  FaHome,
  FaInfoCircle,
  FaWeight,
  FaPeopleCarry,
  FaDollarSign,
  FaImages,
  FaEnvelopeOpenText,
  FaBlog,
  FaTicketAlt,
} from "react-icons/fa"
import { Link } from "gatsby"

// Styles
import styles from "../styles/components/navdrawer.module.scss"

const materialStyles = {
  list: {
    width: 250,
  },
  paper: {
    background: "#192024",
    top: 0,
  },
}

class TemporaryDrawer extends React.Component {
  state = {
    left: false,
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    })
  }

  render() {
    const { classes } = this.props
    const blogList = (
      <div className={styles.navdrawer}>
        <div className={styles.logo}>
          <MobileLogo />
        </div>
        <div className={classes.list}>
          <List>
            <Link to="/" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaHome />
                  </span>
                  Home
                </h3>
              </ListItem>
            </Link>
            {/* <Link to="/reservations" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaHome />
                  </span>
                  Reservations
                </h3>
              </ListItem>
            </Link>
            <Link to="/cancel-reservation" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaHome />
                  </span>
                  Cancel Reservation
                </h3>
              </ListItem>
            </Link> */}
          </List>
        </div>
      </div>
    )
    const sideList = (
      <div className={styles.navdrawer}>
        <div className={styles.logo}>
          <MobileLogo />
        </div>
        <div className={classes.list}>
          <List>
            <a href="#home" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaHome />
                  </span>
                  Home
                </h3>
              </ListItem>
            </a>

            <a href="#about" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaInfoCircle />
                  </span>
                  About
                </h3>
              </ListItem>
            </a>

            <a href="#services" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaWeight />
                  </span>
                  Services
                </h3>
              </ListItem>
            </a>

            <a href="#team" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaPeopleCarry />
                  </span>
                  Team
                </h3>
              </ListItem>
            </a>

            <a href="#pricing" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaDollarSign />
                  </span>
                  Pricing
                </h3>
              </ListItem>
            </a>

            <a href="#gallery" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaImages />
                  </span>
                  Gallery
                </h3>
              </ListItem>
            </a>

            <a href="/#contact" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaEnvelopeOpenText />
                  </span>
                  Contact
                </h3>
              </ListItem>
            </a>

            <Link to="/blog" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaBlog />
                  </span>
                  Blog
                </h3>
              </ListItem>
            </Link>

            {/* <Link to="/reservations" className={styles.link}>
              <ListItem button>
                <h3>
                  <span>
                    <FaTicketAlt />
                  </span>
                  Make Reservation
                </h3>
              </ListItem>
            </Link> */}
          </List>
        </div>
      </div>
    )

    return (
      <div>
        <div
          id="nav-icon"
          className={this.state.right ? "open" : "close"}
          onClick={this.toggleDrawer("right", true)}
          role="button"
          tabIndex={0}
          onKeyDown={this.toggleDrawer("right", true)}
        >
          <span />
          <span />
          <span />
        </div>
        <Drawer
          classes={{ paper: classes.paper }}
          anchor="left"
          open={this.state.right}
          onClose={this.toggleDrawer("right", false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("right", false)}
            onKeyDown={this.toggleDrawer("right", false)}
          >
            {this.props.blog ? blogList : sideList}
          </div>
        </Drawer>
      </div>
    )
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(materialStyles)(TemporaryDrawer)
